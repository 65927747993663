.last {
    display: flex;
    gap: 10px;
}

@media screen and (max-width:500px) {
    .last {
        flex-direction: column;
        margin-top: 20px;
    }
}