.banner {
    background-color: #243A5E;
    padding: 50px 0 30px 0;
    color: #fff;
}

.banner h1 {
    display: contents;
    font-size: 40px;
    font-weight: 100;
}