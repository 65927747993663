body {
  margin: 0;
  font-family: 'Microsoft Yahei', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pointer {
  cursor: pointer;
}

a,
a:hover {
  color: #0078d4;
}

@keyframes details-show {
  from {
    opacity: 0;
    transform: var(--details-translate, translateY(-0.5em));
  }
}

details[open]>.body {
  animation: details-show 150ms ease-in-out;
}

details>.body {
  animation: details-show 150ms ease-in-out;
}

.fluentliteui.dropdown-wapper .value .mask {
  opacity: 1 !important;
}

.fluentliteui .popover-pop {
  z-index: 9999999 !important;
}


.fluentliteui.pagenav button {
  min-width: 32px;
  min-height: 32px;
  height: 32px;
  line-height: 34px;
  font-size: 16px;
  padding: 0 5px 0 5px;
  color: #3e3e3e;
  font-size: 12px;
  border: none !important;
}

.fluentliteui.pagenav button.ellipsis {
  /* border: none; */
}

.fluentliteui.page.primary button {
  /* background: none;
  color: #0078d4;
  border: solid 1px #0078d4 !important; */
  color: #fff;
}

.fluentliteui.pagenav button[disabled] {
  color: #999;
}

.fluentliteui tbody td {
  font-size: 14px;
}

.fluentliteui.dropdown-wapper .dropdown {
  font-size: 14px;
}