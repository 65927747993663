.header .mob {
    display: none;
}

@media screen and (max-width:590px) {
    .header .width-wapper {
        flex-direction: column;
    }

    .header details {
        display: flex;
    }

    .apply-and-portal {
        width: 100% !important;
    }

    .header .mob {
        display: block;
    }

    .header .pc {
        display: none;
    }

    .logo {
        position: relative;
    }

    .header details .title {
        flex: 1;
    }

    .mobile-menu {
        display: block;
        transition-duration: 0.75s;
        width: 22px;
        height: 22px;
        background-image: url("/public/images/svg/menu.svg");
        background-size: 100%;
        cursor: pointer;
    }

    details[open] .mobile-menu {
        transition-duration: 0.75s;
        background-image: url("/public/images/svg/close.svg");
    }

    .header .logo-and-links {
        flex-direction: row;
    }

    .header .logo-and-links ul.links li {
        width: 100% !important;
    }

    .header .logo-and-links ul.links li a:hover {
        border-bottom: none !important;
    }

    .header-menus {
        margin: 10px 0 0 0;
        padding: 0;
    }

    .header-menus li {
        list-style: none;
        border-bottom: 1px solid rgba(255, 255, 255, .04);
        padding: 4px 0 4px 0;
    }

    .header-menus li a {
        display: block;
        width: 100% !important;
        text-decoration: none;
        color: #fff;
    }

    .header-menus li a:hover {
        color: #0078d4;
    }

    details.fluentliteui summary {
        border: none;
    }
}

@media screen and (max-width:375px) {
    .header .logo-and-links .logo {
        height: 18px;
    }

    .header .mobile-menu {
        width: 18px;
        height: 18px;
    }
}