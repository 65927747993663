@media screen and (max-width:800px) {
    .follow-us {
        zoom: 0.8;
    }
}

@media screen and (max-width:760px) {
    .follow-us {
        zoom: 0.7;
    }

    .footer-help {
        display: block !important;
    }

    .footer-help a {
        display: inline-flex;
        float: left;
        margin-right: 10px;
        gap: 5px;
    }



    .footer .width-wapper .footer-help {
        display: block;
    }
}

@media screen and (max-width:675px) {
    .follow-us {
        zoom: 0.7;
    }

    .footer-navigation {
        flex-direction: column !important;
    }

    .follow-us {
        flex-direction: row !important;
        justify-content: space-around;
    }

    .item {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .follow-us .item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .menu-child li {
        margin-right: 8px;
        float: left;
    }

    .footer-help {
        margin-top: 10px;
    }
}

@media screen and (max-width:500px) {
    .unmob {
        display: none;
    }

    .footer .item {
        list-style: none;
        border-bottom: 1px solid rgba(255, 255, 255, .04);
        padding: 4px 0 4px 0;
    }

    .follow-us .item {
        border: none;
    }

    .footer .details-child {
        margin-top: 10px;
    }

    details {
        display: flex !important;
    }

    .icon-arrow-down {
        display: block;
        width: 22px;
        height: 22px;
        background-image: url('/public/images/svg/arrow-down.svg');
        background-size: 100%;
    }

    details .title {
        color: #a9a9a9;
        flex: 1;
    }

    details[open] .icon-arrow-down {
        transform: rotate(180deg);
    }

    details li {
        padding-bottom: 8px;
    }

    .header-menus li a {
        display: block;
        width: 100% !important;
        text-decoration: none;
        color: #fff;
    }
}