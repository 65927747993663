.header {
    background-color: #1A1A1A;
    height: 79px;
}

.header .width-wapper {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.header .logo-and-links {
    display: flex;
    flex-direction: row;
    flex: 9;
    align-items: center;
    gap: 20px;
}

.header .logo-and-links .logo {
    width: 320px;
    height: 22px;
}

.header .logo-and-links .logo.en-us {
    background: url("/public/images/svg/A.cn_logo_dark_bg_en.svg") no-repeat left center
}

.header .logo-and-links .logo.zh-cn {
    background: url("/public/images/svg/A.cn_logo_dark_bg_cn.svg") no-repeat left center
}

.header .logo-and-links ul.links {
    list-style: none;
    display: block;
    margin: 10px 0 0 0;
    padding: 0;
}

.header .logo-and-links ul.links li {
    margin: 0 10px 0 0px;
    height: 28px;
    float: left;
}


.header .logo-and-links ul.links li a {
    color: #fff;
    text-decoration: none;
    height: 30px;
    display: block;
}

.header .logo-and-links ul.links li a:hover {
    color: #0078d4;
    border-bottom: solid 2px #0078d4;
}

.header .apply-and-portal {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
    width: 160px;
}

.header .apply-and-portal .portal-link {
    font-size: 12px;
    color: #a9a9a9;
    text-decoration: none;
}

.header .buy-it {
    display: flex;
    background-color: rgb(216, 59, 1);
    width: 100%;
    height: 48px;
    color: #fff;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
}

.header .apply-and-portal .buy-it:hover {
    background-color: #f47119;
}

.buy-it span {
    vertical-align: middle;
}

.icon-arrow-right {
    color: #fff;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i5Zu+5bGCXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMTYgMTYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDE2IDE2OyIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojRkZGRkZGO30NCgkuc3Qxe2ZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO2ZpbGw6I0ZGRkZGRjt9DQo8L3N0eWxlPg0KPHJlY3QgeD0iMTIiIHk9Ii00MyIgY2xhc3M9InN0MCIgd2lkdGg9IjIiIGhlaWdodD0iOCIvPg0KPHBvbHlnb24gY2xhc3M9InN0MSIgcG9pbnRzPSIxMiw3LjUgMTIsNy41IDEyLDcuNSA2LjUsMiA1LjEsMy40IDkuMiw3LjUgNS4xLDExLjYgNi41LDEzICIvPg0KPC9zdmc+DQo=) no-repeat left 1px;
}