.table-wapper {
    width: 100%;
    padding: 0;
    margin-top: 20px;
}

table[style] {
    width: 1500px !important;
    margin: 0;
    padding: 0;
}

@media screen and (max-width:1500px) {
    .table-wapper {
        overflow-x: scroll;
    }
}

@media screen and (max-width:738px) {


    /* 手机端分页适配 */

    .fluentliteui.page button {
        background-color: #fff;
        color: #2f6dbd !important;
    }

    .fluentliteui.page.default:has(.number) {
        display: none;
    }

    .fluentliteui.page.default:has(.ellipsis) {
        display: none;
    }
}

@media screen and (max-width:600px) {
    /* 手机端popover的适配 */

    .fluentliteui .popover-pop {
        width: 0;
        height: 0;
    }

    .fluentliteui .popover-content {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .fluentliteui .pop-body-wapper {
        width: 70% !important;
        height: auto;
        background: #fff;
        padding: 20px;
        position: relative;
        box-shadow: 0 0 10px 1px #ccc;
    }

    .fluentliteui .pop-body-wapper::after {
        position: absolute;
        content: "";
        width: 20px;
        height: 20px;
        right: -10px;
        top: -10px;
        text-align: center;
        line-height: 20px;
        border-radius: 50%;
        box-shadow: 0 0 10px 1px #ccc;
        background-size: 16px;
        background-color: #fff;
        background-position: 2px 2px;
        background-repeat: no-repeat;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAA59JREFUaEPtmM9LFGEYx59nVyxaUqP+Art5sC66M2OzLELQKQ+aUBaCUSQUdCmSDkEHyegSlUQRQUZkRRlIUWHKjLvvjEp0ik5dik5BhyD8te8To87utI47u/O+gwg7l4Wd93ne7+f5Pu/LvC/CFn9wi+uHKsBmO1h1oOqAYAUCWyiltbbnchjP2PYHwbnKDk+p6rElgE+Msa9BQSUBdC3ZgRAbc5IQ0W2TWeeCEoq8b2pqqt1dX/cOEdNEtBjntHfKtn+UyhkE0IkQe+EmIKD7ZtY6LSJyo1iv+LUxy7C03GjMzn4PDeAE6poyjID9UUKk0+ntuYX5t07l19zmCHjCYOxJULEC10DUEKviFyYQQVsTTwh4vBzxzviyAJyBKVV9AAh9Mp3wE09IJ6ez9sOgyrvvywaQDSFDfEUOuMS6pjxGwB4RJ4rFO7k48L5KKh/KgbUg1DVlxAsBBI8MxnrLsb25uTlRn0i8d3t+pe859ZuWdbec+OIxFbWQJzgUhCO+IZGYBISWvIMC4kO10H8QqvoMEbry/5Vwwk88ED9vMPtmmMqLtJB3vpiuqqNBEFGJF3XABYmlNOUVAB72c6KtrW1nnPMJb9vIqLwsB/KaU5ry2gtBQE//LiydSWyrnQKA/QU4PmAw+5pI23hjwy5i3/l1TR1DgI7CFgu/EWCXZ8FeNi1rUJZ4WS3k1RPXVWUcEQ8ViyTiV01mX5EpPgoAWN3nd/xExLp85YlmTGYlZYuXDpBOp2v44vwbADy43gG4YTJ2QTaE3DWgKs4nsbd9PgJAe0E0DRlZ65JMCCkAxZUn50Hqnc7aI7qm3EPAU552knqyEwZwTlJ7GurG3bbxindFRwkhBFB8DPQTHzVEaAA/8Uhw1LCs0Y16PAonQgGEER+VExUD+BzAKajyxY6sc0LgtqMiAN8DeEDbbNhOqnILEc8WPjvCXdmUDeBzDMwBp55SPR+03+sSIMoC8BNPBF0mYyu3diKPKEQgQJTi8wtbwImSAI54vjg/CYCKOxkn6J5m7LlI1f1idTV5HTFW+FYiuGMwll8jG80XcDeq9CPgcGGh8SNm1s7flUqH0JQhBLzo5s1hriWTmZkrNU9JgAOtrftiNfHPKwk4dRqW9VK26HVbrKoOIsIAAP3KYbwxk8n8CQ3gBOrJZJPza9r2l6jFu/lTitLNl5fnpufmvgXNGbiIgxJs9vsqQNUBwQpUW0iwgMLhVQeESyiY4B9yCwRPxcLHWgAAAABJRU5ErkJggg==');
    }

    .popover-arrow {
        display: none !important;
    }
}