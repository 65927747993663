.dropdownItem {
    flex: 1 !important;
}

.fluentliteui.dropdown-wapper {
    width: 100%;
}

.fluentliteui.dropdown-wapper .label {
    width: 80px;
}

.pad {
    display: none;
}

.mob {
    display: none;
}

@media screen and (max-width:1000px) {
    .pc {
        display: none;
    }

    .pad {
        display: block;
    }

    .mob {
        display: none;
    }
}

@media screen and (max-width:675px) {
    .pc {
        display: none;
    }

    .pad {
        display: none;
    }

    .mob {
        display: block;
    }
}