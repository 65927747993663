@import "./header.css";
@import "./dropdown.css";
@import "./result.css";
@import "./footer.css";

details {
    display: none;
}

.width-wapper {
    /* width: calc(100% - 20px); */
    max-width: 1500px;
    margin: 0 auto;
}

@media screen and (max-width:1500px) {
    .width-wapper {
        width: calc(100% - 100px);
    }
}

@media screen and (max-width:675px) {
    .width-wapper {
        width: calc(100% - 40px);
    }
}


@media screen and (max-width:500px) {
    .width-wapper {
        width: calc(100% - 20px);
    }
}